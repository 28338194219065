export default {
  login: {
    login: 'Đăng nhập',
    nameRequire: 'tên người dùng là bắt buộc',
    pwdRequire: 'mật khẩu được yêu câu'
  },
  main: {
    last7days: '7 ngày qua',
    last30days: '30 ngày qua',
    branches: 'cửa tiệm',
    amount: 'số tiền',
    qty: 'số lượng',
    date: 'ngày tháng'
  },
  common: {
    cancel: 'Hủy bỏ',
    confirm: 'Xác nhận',
    normal: 'thông thường',
    disabled: 'Vô hiệu hóa',
    deleted: 'Đã xóa',
    pleaseEnter: 'Vui lòng nhập',
    pleaseSelect: 'xin vui lòng chọn',
    confirmDelete: 'xác nhận xóa?',
    confirmCancel: 'Bạn có chắc chắn hủy đơn hàng không?',
    confirmDisable: 'xác nhận vô hiệu hóa?',
    tip: 'gợi ý',
    deleteSuccess: 'xóa thành công',
    duplicateName: 'tên trùng lặp',
    addSuccess: 'thêm thành công',
    updateSuccess: 'Cập nhật thành công',
    group: 'Tập đoàn',
    query: 'truy vấn',
    reset: 'cài lại',
    refresh: 'để làm mới',
    uploadAtMostOne: 'Tải lên nhiều nhất một',
    loginAgain: 'Đã sửa đổi thành công, vui lòng đăng nhập lại',
    home: 'trang đầu',
    confirmSignOut: 'Bạn có chắc chắn đăng xuất?',
    save: 'tiết kiệm',
    true: 'ĐÚNG VẬY',
    false: 'sai'
  },
  table: {
    UpdatedBy: 'Cập nhật',
    UpdatedDate: 'Ngày cập nhật',
    CreatedBy: 'Được tạo bởi',
    CreatedDate: 'Ngày tạo ra',
    Status: 'Trạng thái',
    Operation: 'Hoạt động',
    Edit: 'Chỉnh sửa',
    Delete: 'Xóa bỏ',
    Disable: 'Vô hiệu hóa',
    add: 'cộng',
    sN: 'số seri',
    import: 'nhập khẩu',
    export: 'xuất khẩu',
    keyword: 'từ khóa'
  },
  branch: {
    branchName: 'Tên chi nhánh',
    id: 'TÔI',
    Logo: 'Logo',
    Splash: 'giật gân',
    FeaturePhoto: 'Tính năngẢnh',
    UEN: 'UEN',
    Street: 'Đường phố',
    Block: 'cộng đồng',
    UnitNo: 'đơn vịKhông',
    Contact: 'Liên hệ',
    updateBranch: 'cập nhậtChi nhánh',
    addBranch: 'Thêm chi nhánh',
    name: 'Tên',
    requireName: 'Vui lòng nhập tên cửa hàng'
  },
  category: {
    name: 'tên danh mục',
    id: 'ID',
    parentID: 'ID cha mẹ',
    serviceFee: 'Phí dịch vụ'
  },
  brand: {
    name: 'thương hiệu',
    id: 'ID',
    updateBrand: 'Cập nhật thương hiệu',
    addBrand: 'thêm thương hiệu',
    requireName: 'Tên là bắt buộc'
  },
  vendor: {
    name: 'Tên nhà cung cấp',
    id: 'ID',
    updateVendor: 'Cập nhật nhà cung cấp',
    addVendor: 'Thêm nhà cung cấp',
    requireName: 'Tên là bắt buộc',
    country: 'dân tộc',
    address: 'Địa chỉ',
    contactPerson: 'người liên lạc',
    phone: 'Điện thoại'
  },
  discount: {
    name: 'chiết khấu',
    id: 'ID',
    update: 'cập nhật ưu đãi',
    add: 'ưu đãi mới',
    requireName: 'Tên là bắt buộc',
    discountCode: 'mã giảm giá'
  },
  group: {
    name: 'Tên nhóm',
    id: 'ID',
    update: 'thay mới',
    add: 'cộng',
    requireName: 'Tên là bắt buộc'
  },
  opGroup: {
    name: 'Tên',
    id: 'ID',
    update: 'cập nhật',
    add: 'cộng',
    requireName: 'Tên là bắt buộc',
    groupID: 'Tập đoàn',
    maxChoice: 'số tiền nhiều nhất',
    mustChoose: 'Có bắt buộc không',
    optionGroupID: 'ID nhóm tùy chọn',
    price: 'giá bán',
    optionGroups: 'nhóm tùy chọn'
  },
  payment: {
    name: 'phương thức thanh toán',
    id: 'ID',
    icon: 'Biểu tượng',
    update: 'cập nhật',
    add: 'cộng',
    requireName: 'Tên là bắt buộc'
  },
  printer: {
    name: 'Tên',
    id: 'ID',
    model: 'kiểu mẫu',
    printerAccount: 'tài khoản',
    uKEY: 'UKEY',
    sN: 'SN',
    purpose: 'sử dụng',
    update: 'cập nhật',
    add: 'cộng',
    requireName: 'Tên là bắt buộc',
    requireBranch: 'cửa hàng là bắt buộc',
    requireSN: 'SN là bắt buộc',
    type: 'các loại',
    wifi: 'WiFi',
    bluetooth: 'Bluetooth'
  },
  sku: {
    name: 'tên sản phẩm',
    id: 'ID',
    photo: 'Hình ảnh sản phẩm',
    distribute: 'phân phát',
    distributeProduct: 'phân phát',
    importItem: 'nhập khẩu',
    mainCategory: 'danh Mục chính',
    confirmExport: 'Bạn có chắc chắn để xuất khẩu?',
    groupID: 'Id nhóm',
    branchID: 'Mã cửa hàng',
    category: 'phân loại',
    itemCode: 'mã hàng hóa',
    uOM: 'đơn vị đo lường',
    price: 'giá cả hàng hóa',
    barcode: 'mã vạch',
    discount: 'Có giảm giá không',
    offerPrice: 'giá chiết khấu',
    inventoryCount: 'trong kho',
    maxOrder: 'giới hạn mua hàng',
    description: 'mô tả',
    uomName: 'Tên thông số kỹ thuật',
    typeName: 'Tên',
    add: 'cộng',
    update: 'Ôn lại',
    save: 'tiết kiệm',
    productPicture: 'Hình ảnh sản phẩm',
    requireName: 'Tên là bắt buộc',
    brand: 'thương hiệu',
    vendor: 'nhà cung cấp',
    pleaseSelectProductUOM: 'Vui lòng chọn đơn vị đo sản phẩm',
    confirmSave: 'Bạn có chắc chắn để tiết kiệm?',
    purchaseLimit: 'giới hạn số lượng',
    cost: 'Giá cả',
    cannotExceed10M: 'Hình ảnh không thể vượt quá 10M',
    moneyError: 'Vui lòng điền số tiền chính xác',
    inclusiveGST: 'GST bao gồm'
  },
  tableBar: {
    name: 'Tên',
    id: 'ID',
    photo: 'bức ảnh',
    qRCode: 'mã QR',
    locationName: 'tên địa phương',
    branchID: 'Mã cửa hàng',
    capacity: 'Dung tích',
    tableLocation: 'vị trí bàn',
    tableLocationID: 'ID vị trí bảng',
    tableName: 'tên bảng',
    add: 'cộng',
    update: 'Ôn lại',
    branchIDRequire: 'cửa hàng là bắt buộc',
    capacityRequire: 'công suất là cần thiết',
    tableNameRequire: 'Tên bảng là bắt buộc',
    tableLocationIDRequire: 'Vị trí bàn là bắt buộc',
    locationNameRequire: 'Vị trí bàn là bắt buộc',
    download: 'Tải xuống'
  },
  user: {
    id: 'ID',
    branchID: 'Mã cửa hàng',
    groupID: 'Id nhóm',
    add: 'cộng',
    update: 'Ôn lại',
    name: 'tên tài khoản',
    group: 'tập đoàn',
    role: 'Vai trò',
    branch: 'cửa tiệm',
    email: 'Email',
    password: 'mật khẩu mở khóa',
    phone: 'số điện thoại',
    recharge: 'nạp điện',
    rechargeAmount: 'Vui lòng nhập số tiền nạp',
    creditPoints: 'tích phân',
    userInfo: 'thông tin người dùng',
    userName: 'tên tài khoản',
    newPassword: 'mật khẩu mới',
    confirmPassword: 'Xác nhận mật khẩu mới',
    changePassword: 'đổi mật khẩu',
    newpasswordCannotEmpty: 'Mật khẩu mới không được để trống',
    characterslength: 'Ít nhất 6-12 ký tự',
    confirmPasswordCanNotBlank: 'xác nhận mật khẩu không được để trống',
    passwordsNotMatch: '2 mật khẩu đã nhập không khớp'
  },
  report: {
    today: 'Ngày nay',
    yesterday: 'hôm qua',
    Last30days: '30 ngày trước',
    date: 'ngày tháng',
    name: 'tên sản phẩm',
    cost: 'Giá cả',
    salesPrice: 'gia ban',
    qty: 'số lượng',
    total: 'Tổng giá',
    profit: 'lợi nhuận',
    branchName: 'cửa tiệm',
    amount: 'số tiền',
    tableName: 'cái bàn',
    orderNo: 'số thứ tự',
    grandTotal: 'Tổng cộng',
    receipt: 'biên lai',
    transactionDate: 'Ngày Giao dịch',
    transactionTime: 'giờ giao dịch',
    normalPrice: 'giá bình thường',
    discountPercent: 'tỷ lệ chiết khấu',
    memberDiscount: 'giảm giá thành viên',
    serviceCharge: 'phi dịch vụ',
    gST: 'thuế bán hàng',
    addtionalDiscount: 'Lợi ích kèm theo',
    paymentMethod: 'phương thức thanh toán',
    customerName: 'Tên khách hàng',
    memberID: 'Mã thành viên',
    memberCardNo: 'số thẻ thành viên',
    pleaseSelectDate: 'Vui lòng chọn một ngày',
    view: 'Kiểm tra'
  },
  route: {
    categoryManage: 'Quản lý danh mục',
    branchManage: 'quản lý cửa hàng',
    groupSetting: 'Cài đặt nhóm',
    productManage: 'quản lý hàng hóa',
    productList: 'danh sách sản phẩm',
    UOM: 'Sự chỉ rõ',
    tableManage: 'quản lý bảng',
    TableLocation: 'vị trí bàn',
    TableList: 'danh sách bảng',
    userManage: 'Quản lý người dùng',
    Staff: 'Nhân Viên',
    User: 'hội viên',
    Modifier: 'thuộc tính hàng hóa',
    Options: 'không bắt buộc',
    paymentManage: 'quản lý thanh toán',
    printerManage: 'máy in',
    Reports: 'Trung tâm báo cáo',
    itemDailySales: 'Báo cáo bán hàng ngày',
    salesAmount: 'Báo cáo bán hàng',
    transaction: 'Ghi lại giao dịch',
    transactionDetails: 'chi tiết giao dịch',
    brandManage: 'quản lý thương hiệu',
    vendorManage: 'Quản lý nhà cung ứng',
    groupManage: 'Quản lý nhóm'
  },
  groupSet: {
    MustSelectTable: 'PhảiChọnBảng',
    ServiceChargeRate: 'Phí dịch vụ',
    InclusiveGST: 'Cho dù sản phẩm bao gồm thuế',
    CurrencySign: 'Tiền tệKý hiệu',
    Currency: 'Tiền tệ',
    GSTRate: 'Tỷ lệ GST'
  },
  settings: {
    title: 'Cài đặt kiểu trang',
    theme: 'Màu chủ đề',
    tagsView: 'Mở Thẻ-Xem',
    fixedHeader: 'Tiêu đề cố định',
    sidebarLogo: 'Biểu trưng thanh bên',
    userCenter: 'trung tâm cá nhân',
    layoutSettings: 'Cài đặt Giao diện',
    signOut: 'đăng xuất',
    save: 'tiết kiệm',
    reset: 'cài lại'
  },
  tagsView: {
    refresh: 'Làm mới',
    close: 'Gần',
    closeOthers: 'Đóng Khác',
    closeAll: 'Đóng tất cả'
  }
}
