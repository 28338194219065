<template>
  <div id="app" v-title :data-title="$t('main.title')" class="bg-surface">
    <div class="bg-brand pt-16 w-full">
      <div class="w-[1000px] mx-auto">
        <div class="flex items-center justify-between">
          <div class="flex items-center mb-8">
            <img src="@/assets/icon.png" class="w-[40px]" />
            <img src="@/assets/union.png" v-if="isChina" class="h-[26px] ml-3" />
            <img src="@/assets/lingocat.png" class="h-[26px] ml-3" />
          </div>
          <div class="w-[128px] rounded-[20px] border border-white bg-brand overflow-hidden flex items-center">
            <div class="flex-1 py-2" @click="doChangeLan" :class="isChina ? 'bg-white text-[#000]' : 'text-white'">中</div>
            <div class="flex-1 py-2" @click="doChangeLan" :class="isChina ? 'text-white' : 'bg-white text-[#000]'">En</div>
          </div>
        </div>
        <div class="flex items-end">
          <img src="@/assets/app1.png" class="flex-1 w-1/2 mr-14" />
          <div class="flex-1 text-white text-left ml-14">
            <p class="font-[900] text-[60px]" v-if="isChina">{{ $t('main.title2') }}</p>
            <p class="font-[900] text-[60px]" v-else>{{ $t('main.title2') }}</p>
            <p class="font-[500] text-[30px] mt-6">{{ $t('main.desc') }}</p>
            <p class="font-[500] text-[18px] mt-6">{{ $t('main.desc1') }}</p>
            <div class="flex items-center mt-16 pb-6">
              <div class="bg-text1 py-4 px-5 rounded-[12px] cursor-pointer flex items-center" @click="goToAppStore">
                <img src="@/assets/icon-apple.png" class="w-[40px] mr-2" />
                <div>
                  <div class="text-lg">App Store</div>
                  <div class="text-sm">{{ $t('main.download') }}</div>
                </div>
              </div>
              <div class="bg-text1 py-4 px-5 rounded-[12px] ml-10 flex items-center">
                <img src="@/assets/android.png" class="w-[40px] mr-4" />
                <div>
                  <div class="text-lg">Android</div>
                  <div class="text-sm">{{ $t('main.stayTuned') }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="bg-surface w-[1000px] py-[56px] mx-auto">
      <div class="flex items-start">
        <img src="@/assets/logo2.png" class="w-[140px] shrink-0 mr-9" />
        <div class="text-left">
          <div class="text-[28px] font-bold text-text1">{{ $t('main.introduce') }}</div>
          <div class="text-[18px] text-text2 leading-8 mt-3">
            {{ $t('main.introduce2') }}
          </div>
        </div>
      </div>
      <div class="grid grid-cols-2 gap-10 mt-20">
        <div class="bg-white rounded-2xl py-8 flex flex-col justify-center items-center">
          <div class="text-[32px] leading-8 font-bold">{{ $t('main.func1') }}</div>
          <div class="text-lg text-text2 mt-3">{{ $t('main.func1Desc') }}</div>
          <img src="@/assets/grid1.png" class="w-1/2 mt-10" />
        </div>
        <div class="bg-white rounded-2xl py-8 flex flex-col justify-center items-center">
          <div class="text-[32px] leading-8 font-bold">{{ $t('main.func2') }}</div>
          <div class="text-lg text-text2 mt-3">{{ $t('main.func2Desc') }}</div>
          <img src="@/assets/grid2.png" class="w-1/2 mt-10" />
        </div>
        <div class="bg-white rounded-2xl py-8 flex flex-col justify-center items-center">
          <div class="text-[32px] leading-8 font-bold">{{ $t('main.func3') }}</div>
          <div class="text-lg text-text2 mt-3">{{ $t('main.func3Desc') }}</div>
          <img src="@/assets/grid3.png" class="w-1/2 mt-10" />
        </div>
        <div class="bg-white rounded-2xl py-8 flex flex-col justify-center items-center">
          <div class="text-[32px] leading-8 font-bold">{{ $t('main.func4') }}</div>
          <div class="text-lg text-text2 mt-3">{{ $t('main.func4Desc') }}</div>
          <img src="@/assets/grid4.png" class="w-1/2 mt-10" />
        </div>
      </div>
    </div>
    <div class="w-full bg-surface3 h-[130px] text-lg text-text3 flex flex-col justify-center text-center">
      <div>© 2023 {{ $t('main.company') }} | liulimao.com | All rights reserved.</div>
      <div><a href="https://beian.miit.gov.cn/" target="_blank">蜀ICP备2023015312号</a></div>
    </div>
  </div>
</template>

<script>
import Cookies from 'js-cookie'
export default {
  name: 'MainPage',
  props: {},
  data() {
    return {
      isChina: true
    }
  },
  computed: {
    language() {
      return Cookies.get('language')
    }
  },
  mounted() {
    this.isChina = this.language == 'zh'
  },
  methods: {
    goToAppStore() {
      const appStoreUrl = 'https://apps.apple.com/app/6447322808'
      window.open(appStoreUrl, '_blank')
    },
    doChangeLan() {
      this.isChina = !this.isChina
      let lang = ''
      if (this.isChina) {
        lang = 'zh'
      } else {
        lang = 'en'
      }
      Cookies.set('language', lang)
      this.$i18n.locale = lang
    }
  }
}
</script>

<style scoped></style>
