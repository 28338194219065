export default {
  main: {
    title: '流利猫 - 你的专属AI英语口语教练',
    title2: '流利猫',
    desc: '你的专属AI英语口语教练,AI助你高效学习',
    desc1: '与流利猫一起，轻松畅游英语世界，享受沉浸式学习，提升英语口语能力，成为自信流利的英语演说家。',
    download: '下载',
    stayTuned: '敬请期待',
    introduce: '应用介绍',
    introduce2:
      '流利猫是一款基于人工智能技术的语言学习应用程序，旨在帮助用户提高英语口语能力和流利度。该应用可以为用户提供个性化的学习体验，包括实时对话和语音识别技术，帮助用户练习英语口语并提供反馈和建议。流利猫还提供了大量的语言学习资源，包括练习题、听力材料和语音录制等，让用户可以随时随地进行英语学习。流利猫的学习内容涵盖了从基础词汇到高级语法的各个方面，适合不同级别的学习者使用。',
    func1: 'AI口语场景练习',
    func1Desc: '丰富的聊天场景,雅思面试模拟',
    func2: '高效学习单词',
    func2Desc: '遵循艾宾浩斯记忆曲线，丰富词库',
    func3: '语法纠正',
    func3Desc: '提供翻译解析和自动纠错功能',
    func4: '英语学习工具',
    func4Desc: '丰富的学习工具, 助你英语游刃有余',
    company: '成华区开车么网络工作室'
  }
}
