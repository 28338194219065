export default {
  main: {
    title: 'Lingocat - your exclusive AI English speaking coach',
    title2: 'Lingocat',
    desc: 'Your exclusive AI spoken English coach',
    desc1:
      'With Lingocat, you can easily swim in the English world, enjoy immersive learning, improve your oral English skills, and become a confident and fluent English speaker. ',
    download: 'download',
    stayTuned: 'Stay tuned',
    introduce: 'Application Introduction',
    introduce2:
      'Lingocat is a language learning application based on artificial intelligence technology, designed to help users improve their English speaking ability and fluency. The app can provide users with a personalized learning experience, including real-time dialogue and voice recognition technology, to help users practice their spoken English and provide feedback and suggestions. Fluentmall also provides a large number of language learning resources, including practice questions, listening materials, and voice recordings, so that users can learn English anytime, anywhere. The learning content of Fluent Cat covers all aspects from basic vocabulary to advanced grammar, suitable for learners of different levels. ',
    func1: 'AI speaking scene practice',
    func1Desc: 'Rich chat scenes, IELTS interview simulation',
    func2: 'Learn words efficiently',
    func2Desc: 'follow the Ebbinghaus memory curve, enrich the lexicon',
    func3: 'grammar correction',
    func3Desc: 'Provide translation analysis and automatic error correction functions',
    func4: 'English learning tools',
    func4Desc: 'Rich learning tools to help you master English',
    company: 'Chenghua District Drive? Network Studio'
  }
}
